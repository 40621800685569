<template>
  <div>
    <div class="news_info">
      <div class="news_info_title">
        <p>新闻资讯<span>NEWS</span></p>
        <span
          class="seeMore"
          @click="jump('/newsInfo?routeType=2&tabIsShow=true&tabType=xwzx')"
          >查看更多</span
        >
      </div>
      <div class="news_both_sides">
        <img src="@/assets/img/tourismManagementImg/3.jpg" alt="" />
        <ul>
          <li>
            旅科院召开全体惯培生返校前教育暨疫情防控主题年纪大会疫情防控主题年纪大会
          </li>
          <li>
            旅科院召开全体惯培生返校前教育暨疫情防控主题年纪大会疫情防控主题年纪大会
          </li>
          <li>旅科院召开全体惯培生返校前教育暨疫情防控主题年纪大会</li>
        </ul>
      </div>
      <div class="news_center">
        <img src="@/assets/img/tourismManagementImg/3.jpg" alt="" />
        <h4>2022-09-20</h4>
        <h4>旅科院召开全体管培生返现前教育暨疫情防控主题年纪大会</h4>
        <p>
          今日，中国人力资源开发研究会公布了首届优秀成果获奖名单。经通讯初评，评委员会复评、研究会终评，我校旅游科学学院李朋波副教授团队申报的《何以卓越：世界一流觉得怕忘打卡我看到啦我看到啦未开票的》...
        </p>
        <div class="news_center_sw">
          <button class="button_left">></button>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <button class="button_right">></button>
        </div>
      </div>
      <div class="news_both_sides" style="margin: 0 5.20833vw 0 2.60417vw">
        <img src="@/assets/img/tourismManagementImg/3.jpg" alt="" />
        <ul>
          <li>
            旅科院召开全体惯培生返校前教育暨疫情防控主题年纪大会疫情防控主题年纪大会
          </li>
          <li>旅科院召开全体惯培生返校前教育暨疫情防控主题年纪大会</li>
          <li>
            旅科院召开全体惯培生返校前教育暨疫情防控主题年纪大会疫情防控主题年纪大会
          </li>
        </ul>
      </div>
    </div>
    <div class="notice">
      <div class="notice_title">
        <p>通知公告<span>ANNOUNCEMENT</span></p>
        <span
          class="seeMore"
          @click="
            jump('/noticeAnnouncement?routeType=2&tabIsShow=true&tabType=tzgg')
          "
          >查看更多</span
        >
      </div>
      <div class="notice_module">
        <dl style="background: rgba(212, 13, 13, 0.842); color: #fff">
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
        <dl>
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
        <dl>
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
        <dl>
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
      </div>
    </div>
    <div class="trends">
      <div class="trends_title">
        <p>学工动态<span>ACADEMIC DYNAMICS</span></p>
        <span
          class="seeMore"
          @click="jump('/studentWork?routeType=2&tabIsShow=true&tabType=xgdt')"
          >查看更多</span
        >
      </div>
      <div class="trends_center">
        <dl>
          <dt style="background: #a12916; color: #fff">
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd>
            <img
              class="trends_center_img"
              src="@/assets/img/tourismManagementImg/3.jpg"
              alt=""
            />
            <h4>
              旅科苑召开全体惯培生返校前教育暨疫情防控主题年级大会旅科苑召开全体惯培生返
            </h4>
            <img
              class="towards_right"
              src="@/assets/img/tourismManagementImg/1699608309672.jpg"
              alt=""
            />
          </dd>
        </dl>
        <dl>
          <dt>
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd>
            <img
              class="trends_center_img"
              src="@/assets/img/tourismManagementImg/3.jpg"
              alt=""
            />
            <h4>
              旅科苑召开全体惯培生返校前教育暨疫情防控主题年级大会旅科苑召开全体惯培生返
            </h4>
            <img
              class="towards_right"
              src="@/assets/img/tourismManagementImg/1699608309672.jpg"
              alt=""
            />
          </dd>
        </dl>
        <dl>
          <dt>
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd>
            <img
              class="trends_center_img"
              src="@/assets/img/tourismManagementImg/3.jpg"
              alt=""
            />
            <h4>
              旅科苑召开全体惯培生返校前教育暨疫情防控主题年级大会旅科苑召开全体惯培生返
            </h4>
            <img
              class="towards_right"
              src="@/assets/img/tourismManagementImg/1699608309672.jpg"
              alt=""
            />
          </dd>
        </dl>
        <dl>
          <dt>
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd>
            <img
              class="trends_center_img"
              src="@/assets/img/tourismManagementImg/3.jpg"
              alt=""
            />
            <h4>
              旅科苑召开全体惯培生返校前教育暨疫情防控主题年级大会旅科苑召开全体惯培生返
            </h4>
            <img
              class="towards_right"
              src="@/assets/img/tourismManagementImg/1699608309672.jpg"
              alt=""
            />
          </dd>
        </dl>
      </div>
      <p class="buttonGroup">
        <button class="button_left">></button>
        <button class="button_right">></button>
      </p>
    </div>
    <div class="scientific">
      <div class="scientific_title">
        <p>科学科研<span>TEACHING AND RESEARCH</span></p>
        <span class="seeMore">查看更多</span>
      </div>
      <div class="scientific_left">
        <div v-for="(item, index) in scienceList" :key="index">
          <dl :class="index == scienceIndex ? 'science_hover' : ''" @click="scienceClick(item,index)">
            <dt>
              <img :src="item.imgSrc" alt="" />
            </dt>
            <dd>
              <p>{{ item.name }}</p>
              <p>{{ item.enName }}</p>
            </dd>
          </dl>
        </div>
      </div>
      <div class="scientific_right">
        <div>
          <p>{{ imgTitle }}<span @click="jump(detailsJumpPath)">详情</span></p>
          <p>
            旅游管理学院的奔了教堂在量具于依靠地位，具有以下几方面的特点：一：办学经验丰富，早在80年代初就开办旅游专业，为国家首批开办本科旅游专业的院校；二：办学特色明显，毕业生具备了扎实的旅游专业基础知识和较强的外语能力，适应我国改革开放对人才的需求旅游管理学院的奔了教堂在量具于依靠地位，具有以下几方面的特点：一：办学经验丰富，早在80年代初就开办旅游专业，为国家首批开办本科旅游专业的院校；二：办学特色明显，毕业生具备了扎实的旅游专业基础知识和较强的外语能力，适应我国改革开放对人才的需求...
          </p>
        </div>
      </div>
    </div>
    <div class="noTitle_context">
      <dl>
        <dt>
          <p>08</p>
          <p>2022.08</p>
        </dt>
        <dd style="color: #a12916">
          <h4>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设 推进文化贸易高质量发展》
          </h4>
          <p>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设登光明日报推进文化贸易高质量发展》文章刊登
          </p>
        </dd>
      </dl>
      <dl>
        <dt>
          <p>08</p>
          <p>2022.08</p>
        </dt>
        <dd>
          <h4>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
          </h4>
          <p>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量发展助力文化强国建设
            推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
          </p>
        </dd>
      </dl>
      <dl>
        <dt>
          <p>08</p>
          <p>2022.08</p>
        </dt>
        <dd>
          <h4>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
          </h4>
          <p>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设
            推进院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
          </p>
        </dd>
      </dl>
      <dl>
        <dt>
          <p>08</p>
          <p>2022.08</p>
        </dt>
        <dd>
          <h4>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
          </h4>
          <p>李嘉珊副院长李嘉珊副院长《助力文化强国建设 推进文化贸易高质</p>
        </dd>
      </dl>
      <dl>
        <dt>
          <p>08</p>
          <p>2022.08</p>
        </dt>
        <dd>
          <h4>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
          </h4>
          <p>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量副院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
          </p>
        </dd>
      </dl>
      <dl>
        <dt>
          <p>08</p>
          <p>2022.08</p>
        </dt>
        <dd>
          <h4>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量发展》文
          </h4>
          <p>
            李嘉珊副院长李嘉珊副院长《助力文化强国建设
            推进文化贸易高质量发展高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
          </p>
        </dd>
      </dl>
    </div>
    <div class="trade_union">
      <div class="trends_title">
        <p>党群工会<span>PARTY AND MASS TRADE UNIONS</span></p>
        <span
          class="seeMore"
          @click="jump('/tradeUnion?routeType=2&tabIsShow=true&tabType=dqgh')"
          >查看更多</span
        >
      </div>
      <div class="trade_union_left">
        <img src="@/assets/img/tourismManagementImg/1699688655453.jpg" alt="" />
        <h3>2022-09-20</h3>
        <p>
          在民族复兴征程上勇当先锋、倾情奉献
          ————旅游科学学院党委开展庆祝建党101周年主题党日活动庆祝建党101周年主题党日
        </p>
        <button class="button_left">></button>
        <button class="button_right">></button>
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div class="trade_union_right">
        <dl style="background: rgba(212, 13, 13, 0.842); color: #fff">
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
        <dl>
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
        <dl>
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
        <dl>
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
      </div>
      <img
        class="city"
        src="@/assets/img/tourismManagementImg/1699427795970.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scienceList: [
        {
          name: "本科生教育",
          enName: "UNDERGRADUATE EDUCAYION",
          imgSrc: require("../../../assets/img/tourismManagementImg/11.jpg"),
          path:'/undergraduate?routeType=2&tabIsShow=true&tabType=bksjy'
        },
        {
          name: "研究生教育",
          enName: "GRADUATS STUDY",
          imgSrc: require("../../../assets/img/tourismManagementImg/22.jpg"),
          path:'/postgraduate?routeType=2&tabIsShow=true&tabType=yjsjy'
        },
        {
          name: "科学教育",
          enName: "SCIENTIFIC RESEARCH",
          imgSrc: require("../../../assets/img/tourismManagementImg/22.jpg"),
          path:'/science?routeType=2&tabIsShow=true&tabType=kxjy'
        },
        {
          name: "社会教育",
          enName: "SOCIAL SERVICE",
          imgSrc: require("../../../assets/img/tourismManagementImg/22.jpg"),
          path:'/society?routeType=2&tabIsShow=true&tabType=shjy'
        },
      ],
      scienceIndex: 0,
      imgTitle:'本科生教育',
      detailsJumpPath:'/undergraduate?routeType=2&tabIsShow=true&tabType=bksjy'
    };
  },
  methods: {
    jump(path) {
      this.$router.push({
        path,
      });
    },
    scienceClick(item,index){
        this.scienceIndex = index
        this.imgTitle = item.name
        this.detailsJumpPath = item.path
    }
  },
};
</script>

<style scoped>
.news_info {
  width: 100%;
  height: 800px;
  background: #f7f7f7;
  padding-top: 20px;
}
.news_info_title {
  height: 100px;
  line-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.news_info_title p {
  width: 200px;
  font-size: 30px;
  margin-left: 100px;
}
.news_info_title p span {
  font-size: 16px;
  color: #999;
  margin-left: 10px;
}
.seeMore {
  font-size: 16px;
  color: #999;
  margin-right: 100px;
  padding-top: 6px;
  cursor: pointer;
}
.news_both_sides {
  width: 400px;
  height: 500px;
  float: left;
  margin-left: 100px;
}
.news_both_sides img {
  width: 100%;
  height: 180px;
}
.news_both_sides ul {
  list-style: none;
}
.news_both_sides ul li {
  height: 100px;
  margin: 20px 0 20px 0;
  line-height: 30px;
  border-bottom: 1px solid #ccc;
}
.news_center {
  float: left;
  width: 800px;
  height: 500px;
  margin-left: 50px;
  position: relative;
}
.news_center img {
  width: 100%;
  height: 350px;
}
.news_center h4 {
  text-align: center;
  margin-top: 24px;
}
.news_center p {
  margin-top: 20px;
}
.news_center_sw {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -150px;
}
.news_center .button_left {
  width: 40px;
  height: 40px;
  background: #f5e9e7;
  color: #a12916;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  float: left;
}
.news_center_sw ul {
  width: 200px;
  height: 40px;
  padding-top: 15px;
  list-style: none;
  margin-left: 350px;
}
.news_center_sw ul li {
  float: left;
  width: 10px;
  height: 10px;
  background: #f5e9e7;
  margin-left: 10px;
  border-radius: 50%;
}
.news_center_sw ul li:first-child {
  background: #a12916;
}
.news_center .button_right {
  width: 40px;
  height: 40px;
  background: #f5e9e7;
  color: #a12916;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 0;
}
.news_center_sw button:hover {
  background: #a12916;
  color: #fff;
  cursor: pointer;
}
/* ---------------------------- */
.notice {
  width: 100%;
  height: 450px;
  background: #0e434c;
  padding-top: 20px;
}
.notice_title {
  height: 100px;
  line-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.notice_title p {
  width: 300px;
  font-size: 30px;
  margin-left: 100px;
  color: #fff;
}
.notice_title p span {
  font-size: 16px;
  color: #999;
  margin-left: 10px;
}
/* ------------------------- */
.notice_module {
  margin-left: 100px;
  display: flex;
  margin-right: 100px;
  justify-content: space-between;
}
.notice_module dl {
  width: 400px;
  height: 250px;
  background: #fff;
}
.notice_module dl dt {
  margin: 50px 0 0 40px;
}
.notice_module dl dt p:first-child {
  font-size: 30px;
}
.notice_module dl dd {
  margin: 50px 40px 0 40px;
}
/* --------------------------- */
.trends {
  width: 100%;
  height: 800px;
  background: #f3f6f7;
  padding-top: 20px;
  position: relative;
}
.trends_title {
  height: 100px;
  line-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.trends_title p {
  width: 400px;
  font-size: 30px;
  margin-left: 100px;
}
.trends_title p span {
  font-size: 16px;
  color: #999;
  margin-left: 10px;
}
.trends_center {
  width: 100%;
  height: 500px;
  /* white-space: nowrap; */
}
.trends_center dl {
  width: 398px;
  height: 100%;
  float: left;
  margin-right: 40px;
}
.trends_center dl:first-child {
  margin-left: 100px;
}
/* .trends_center dl:nth-child(4) {
    margin-right: 100px;
} */
.trends_center dl dt {
  width: 90px;
  height: 100px;
  border: 1px solid;
  border-radius: 10px;
}
.trends_center dl dt p:first-child {
  font-size: 25px;
  text-align: center;
  margin-top: 16px;
}
.trends_center dl dt p:nth-child(2) {
  text-align: center;
  margin-top: 5px;
}
.trends_center dl dd {
  margin-top: 24px;
}
.trends_center_img {
  width: 100%;
  height: 250px;
}
.trends_center dl dd h4 {
  margin-top: 24px;
  line-height: 30px;
}
.towards_right {
  margin-top: 40px;
}
.trends .buttonGroup {
  position: absolute;
  right: 100px;
  bottom: 50px;
}
.trends .button_left {
  width: 40px;
  height: 40px;
  background: #fff;
  color: #a12916;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  float: left;
}
.trends .button_right {
  width: 40px;
  height: 40px;
  background: #fff;
  color: #a12916;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  margin-left: 16px;
}
.buttonGroup button:hover {
  background: #a12916;
  color: #fff;
  cursor: pointer;
}
/* ------------------------ */
.scientific {
  width: 100%;
  height: 800px;
  background: #fefefe;
  padding-top: 20px;
}
.scientific_title {
  height: 100px;
  line-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.scientific_title p {
  width: 400px;
  font-size: 30px;
  margin-left: 100px;
}
.scientific_title p span {
  font-size: 16px;
  color: #999;
  margin-left: 10px;
}
.scientific_left {
  margin-left: 100px;
  margin-right: 24px;
  width: 500px;
  float: left;
}
.scientific_left dl {
  width: 100%;
  height: 150px;
  box-shadow: 1px 1px 3px #ccc;
  display: flex;
}
.science_hover{
  background: #a12916;
  color: white;
}
.scientific_left dl:hover {
  background: #a12916;
  color: white;
}
.scientific_left dl dt {
  width: 100px;
  height: 100%;
  text-align: center;
  border-right: 1px solid #f2e3e0;
}
.scientific_left dl dt img {
  width: 60px;
  height: 50px;
  margin-top: 50px;
  margin-left: 20px;
}
.scientific_left dl dd p:first-child {
  margin: 50px 0 0 70px;
  font-size: 25px;
  cursor: pointer;
}
.scientific_left dl dd p:nth-child(2) {
  width: 300px;
  margin: 10px 0 0 70px;
  color: #999;
}
.scientific_right {
  float: right;
  height: 600px;
  width: 1270px;
  background: url(../../../assets/img/tourismManagementImg/企业微信截图_1699434203479.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: relative;
}
.scientific_right div {
  width: 1100px;
  height: 150px;
  position: absolute;
  left: 50px;
  bottom: 20px;
  border-left: 2px solid #fff;
  padding-left: 24px;
}
.scientific_right div p:first-child {
  font-size: 30px;
}
.scientific_right div span {
  font-size: 20px;
  margin-left: 24px;
  padding: 5px 24px;
  background: white;
  color: #a12916;
  border-radius: 20px;
  cursor: pointer;
}
.scientific_right div p:nth-child(2) {
  margin-top: 24px;
}
/* ----------------------------- */
.noTitle_context {
  height: 700px;
  padding: 100px 0 100px 100px;
  background: #e9f0f7;
}
.noTitle_context dl {
  width: 800px;
  height: 170px;
  border-bottom: 2px solid white;
  float: left;
  margin-right: 100px;
  display: flex;
  padding-top: 50px;
}
.noTitle_context dl dt {
  width: 100px;
  height: 120px;
  border: 1px solid;
  border-radius: 10px;
  margin-right: 24px;
}
.noTitle_context dl:first-child dt {
  border: 1px solid red;
  background: #a12916;
  color: white;
}
.noTitle_context dt p:first-child {
  font-size: 25px;
  text-align: center;
  margin-top: 24px;
}
.noTitle_context dt p:nth-child(2) {
  text-align: center;
  width: 100px;
  margin-top: 5px;
}
.noTitle_context dd {
  width: 650px;
}
.noTitle_context dd p {
  margin-top: 24px;
  line-height: 24px;
  font-size: 14px;
}
/* ---------------------- */
.trade_union {
  width: 100%;
  height: 1200px;
  background: #e0e0dd;
  position: relative;
}
.trade_union_left {
  margin-left: 100px;
  width: 750px;
  height: 700px;
  position: relative;
  float: left;
}
.trade_union_left img {
  width: 100%;
  height: 400px;
}
.trade_union_left h3 {
  margin: 24px 0;
}
.trade_union_left p {
  padding-right: 24px;
  line-height: 24px;
}
.trade_union_left .button_left {
  width: 40px;
  height: 40px;
  background: #f5e9e7;
  color: #a12916;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  float: left;
  margin-top: 24px;
}
.trade_union_left .button_right {
  width: 40px;
  height: 40px;
  background: #f5e9e7;
  color: #a12916;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  float: left;
  margin: 24px 0 0 16px;
}
.trade_union_left button:hover {
  background: #a12916;
  color: #fff;
  cursor: pointer;
}
.trade_union_left ul {
  list-style: none;
  position: absolute;
  right: 0;
  top: 565px;
}
.trade_union_left ul li {
  float: left;
  width: 10px;
  height: 10px;
  background: #f4e7e5;
  margin-left: 10px;
  border-radius: 50%;
}
.trade_union_left ul li:first-child {
  background: #a12916;
}
.trade_union_right {
  width: 950px;
  height: 700px;
  float: left;
  margin-left: 50px;
}
.trade_union_right dl {
  width: 400px;
  height: 260px;
  background: #fff;
  float: left;
  margin: 0 70px 70px 0;
}
.trade_union_right dl dt {
  margin: 50px 0 0 40px;
}
.trade_union_right dl dt p:first-child {
  font-size: 30px;
}
.trade_union_right dl dd {
  margin: 50px 40px 0 40px;
}
.city {
  height: 250px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
